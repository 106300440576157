
import Vue from "vue";

export default Vue.extend({
  name: "MessageFileWrapper",

  props: {
    file: {
      type: Object,
      required: true
    }
  }
});
