
import Vue from "vue";

export default Vue.extend({
  name: "Rooms",

  props: {
    rooms: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: "chat",
    selectedRoom: null
  }),

  computed: {
    sortedRooms() {
      const rooms = [...this.rooms];

      rooms.sort(
        (a: any, b: any) =>
          b.last_message?.created_at - a.last_message?.created_at
      );

      return rooms;
    }
  },

  methods: {
    openChat(room: any = {}) {
      if (room.members_count > 2) {
        this.$emit("openGroup", room.id);
      } else {
        this.$emit("openPeople", room.id);
      }
    },
    getMessageTime(time: number) {
      return new Date(time * 1000)
        .toLocaleTimeString("en-GB", {
          timeZone: "Europe/Chisinau"
        })
        .substring(0, 5);
    }
  }
});
