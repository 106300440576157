
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "CreateRoom",

  components: { FileUploader },

  props: {
    hideBack: {
      default: false,
      required: false,
      type: Boolean
    }
  },

  data: () => ({
    model: {
      members: []
    },
    queryString: "" as string,
    contacts: [
      {
        name: "John Doe",
        avatar: "https://picsum.photos/510/300?random",
        position: "Admin"
      },
      {
        name: "Super manager",
        avatar: "https://picsum.photos/510/300?random",
        position: "Main manager"
      },
      {
        name: "Manager Vasile",
        avatar: "https://picsum.photos/510/300?random",
        position: "Manager"
      },
      {
        name: "Manager Ion",
        avatar: "https://picsum.photos/510/300?random",
        position: "Manager"
      }
    ]
  }),

  methods: {
    showItem(itemName: string) {
      return itemName.toLowerCase().includes(this.queryString.toLowerCase());
    }
  }
});
