
import Vue from "vue";
import { IntersectDirective } from "vue-intersect-directive";
import debounce from "lodash/fp/debounce";
import MessageInput from "@/components/chat/components/MessageInput.vue";
import MessageFileWrapper from "@/components/chat/components/MessageFileWrapper.vue";
import AudioPlayer from "@/components/media/AudioPlayer.vue";

export default Vue.extend({
  name: "PeopleRoom",
  components: { AudioPlayer, MessageFileWrapper, MessageInput },
  directives: {
    intersect: IntersectDirective,
    calculateHeight: {
      inserted(el, binding, vnode) {
        vnode.context?.$nextTick(() => {
          el.style.height = `${el.clientHeight}px`;
        });
      },
      update(el, binding, vnode) {
        vnode.context?.$nextTick(() => {
          el.style.height = `${el.clientHeight}px`;
        });
      }
    }
  },

  props: {
    hideBack: {
      default: false,
      required: false,
      type: Boolean
    },
    room: {
      required: true,
      type: Object
    },
    fullMode: {
      required: false,
      type: Boolean,
      default: false
    },
    initialMessages: {
      required: true,
      type: Array
    }
  },

  data: () => ({
    debounce,
    debounced: null as any,
    offset: {
      scrollPosition: 0,
      scrollHeight: 0
    } as any,
    editMode: false as boolean,
    editedItem: null as any,
    printing: false as boolean,
    messages: [] as Array<any>,
    unreadMessages: [] as Array<any>
  }),

  watch: {
    initialMessages() {
      this.initChat();
    },
    needScrollToBottom(val) {
      if (!val && this.messages.length >= 60) {
        this.removeLastMessages();
      }
    }
  },

  computed: {
    needScrollToBottom() {
      if (!this.offset.scrollHeight && !this.offset.scrollPosition) {
        return false;
      }
      return this.offset.scrollHeight - this.offset.scrollPosition > 10;
    },
    unreadMessagesCount() {
      return this.messages.reduce((accumulator: number, currentValue: any) => {
        if (currentValue.read_at || currentValue._isOwner) {
          return accumulator;
        }
        return accumulator + 1;
      }, 0);
    }
  },

  mounted() {
    this.initChat();
    this.listenNewMessages();
  },

  beforeDestroy() {
    try {
      this.$Pusher.instance().unsubscribe(`Messenger.room.${this.room.id}`);
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    addMessage(message: any) {
      const isOwner =
        message.user_id ===
        this.$store.getters["authentication/credentials"].user.id;

      this.messages.push({
        ...message,
        _show: true,
        _editable:
          !message.files ||
          !message.files.some((el: any) => el.type === "audio"),
        _isOwner: isOwner
      });

      this.scrollToBottom();
    },
    listenReadMessage(messages: any) {
      this.messages.forEach((item: any, index: number) => {
        if (messages.includes(item.id)) {
          this.messages[index].read_at = true;
        }
      });
    },
    listenDeleteMessage(message: any) {
      const index = this.messages.findIndex(item => item.id === message);

      if (index !== -1) {
        this.messages.splice(index, 1);
      }
    },
    listenUpdateMessage(message: any) {
      const isOwner =
        message.user_id ===
        this.$store.getters["authentication/credentials"].user.id;
      const index = this.messages.findIndex(item => item.id === message.id);

      if (index !== -1) {
        this.messages.splice(index, 1, {
          ...message,
          _isOwner: isOwner,
          _editable:
            !message.files ||
            !message.files.some((el: any) => el.type === "audio"),
          _show: true
        });
      }
    },
    listenNewMessages() {
      try {
        this.$Pusher
          .instance()
          .subscribe(`Messenger.room.${this.room.id}`)
          .bind(
            "App\\Events\\Messenger\\NewMessage",
            ({ message_resource }) => {
              this.addMessage(message_resource);
            }
          )
          .bind("App\\Events\\Messenger\\MessageRead", ({ messages }) => {
            this.listenReadMessage(messages);
          })
          .bind("App\\Events\\Messenger\\MessageDeleted", ({ message_id }) => {
            this.listenDeleteMessage(message_id);
          })
          .bind(
            "App\\Events\\Messenger\\MessageUpdated",
            ({ message_resource }) => {
              this.listenUpdateMessage(message_resource);
            }
          );
      } catch (e) {
        console.error(e);
      }
    },
    initChat() {
      this.messages = [...this.initialMessages];

      const firstUnread = this.messages.find(
        item => !item.read_at && !item._isOwner
      );

      if (firstUnread) {
        this.$nextTick(() => {
          const container = this.$refs.messages as any;
          const el = this.$el.querySelector(`#message_${firstUnread.id}`);
          console.dir(el);
          container.scrollTo({
            top: el.offsetTop - 20,
            behavior: "smooth"
          });
        });
      } else {
        this.$nextTick(() => {
          if (!this.needScrollToBottom) {
            this.scrollToBottom();
          }
        });
      }
    },
    getMessageTime(time: number) {
      return new Date(time)
        .toLocaleTimeString("en-GB", {
          timeZone: "Europe/Chisinau"
        })
        .substring(0, 5);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messages as any;
        container.scrollTop = container.scrollHeight;
      });
    },
    editMessage(item: any) {
      this.editMode = true;
      this.editedItem = item;
    },
    removeLastMessages() {
      this.messages = this.messages.slice(-30);
    },
    closeEditable() {
      this.editMode = false;
      this.editedItem = null;
    },
    handleIntersection(isIntersecting: boolean, options: any, el: any) {
      el._show = isIntersecting;

      if (!el._isOwner && !el.read_at && isIntersecting) {
        this.unreadMessages.push(el);

        const [lastMessage] = this.unreadMessages.sort((a, b) => b.id - a.id);

        if (this.debounced) {
          this.debounced.cancel();
        }
        this.debounced = this.debounce(500, () =>
          this.readLastMessage(lastMessage)
        );
        this.debounced();
      }
    },
    async readLastMessage(message: any) {
      try {
        await this.$API.messenger().readMessage(this.room.member_id, {
          last_read_message: message.id
        });
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadNewMessages() {
      if (!this.messages.length) {
        return;
      }

      const [lastMessage] = this.messages;

      try {
        const response = await this.$API.messenger().getRoomLastMessages({
          last_message: lastMessage.id
        });

        this.messages.unshift(
          ...response.map((item: any) => ({
            ...item,
            _show: true,
            _editable:
              !item.files || !item.files.some((el: any) => el.type === "audio"),
            _isOwner:
              item.user_id ===
              this.$store.getters["authentication/credentials"].user.id
          }))
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async deleteMessage(message: any) {
      try {
        await this.$API.messenger().deleteMessage(message.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.scrollToBottom();
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async onScroll(e: any) {
      this.offset = {
        scrollHeight: e.target.scrollHeight - e.target.offsetHeight,
        scrollPosition: e.target.scrollTop
      };

      if (!this.offset.scrollPosition) {
        this.$nextTick(async () => {
          const container = e.target;
          const currentScrollPos = container.scrollTop;
          const oldScroll = container.scrollHeight - container.clientHeight;

          await this.loadNewMessages();

          const newScroll = container.scrollHeight - container.clientHeight;
          container.scrollTop = currentScrollPos + (newScroll - oldScroll);
        });
      }
    }
  }
});
