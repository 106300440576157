
import Vue from "vue";
import draggable from "vuedraggable";
import API from "@/api/API";
import store from "@/store";
import CardDetails from "@/modules/board/components/Details.vue";
import CardCreate from "@/modules/board/components/Create.vue";

export default Vue.extend({
  name: "Board",

  components: {
    CardCreate,
    CardDetails,
    draggable
  },

  data: () => ({
    sources: {
      site: {
        icon: "web",
        color: "primary"
      },
      facebook: {
        icon: "facebook",
        color: "blue"
      }
    },
    columnWidth: "340px" as string,
    cardDetailsDialog: false as boolean,
    cardCreateDialog: false as boolean,
    openCardId: null as any,
    editedColumn: {
      name: "" as string,
      id: null as null | number
    },
    selects: {
      managers: []
    },
    newColumnPopup: false as boolean,
    currentManager: null as null | number,
    updateInterval: null as any,
    items: [] as Array<any>,
    statusForNewCard: null as null | number,
    colors: [
      "primary",
      "aliceblue",
      "aquamarine",
      "bisque",
      "antiquewhite"
    ] as Array<string>,
    newCol: {
      name: "" as string,
      color: "primary" as string
    },
    onDrag: false as boolean
  }),

  watch: {
    newColumnPopup() {
      if (this.newColumnPopup) {
        this.setColumnFocus();
      }
    }
  },

  computed: {
    boardSize() {
      const isExpanded = this.$store.getters["navigation/expanded"];
      return {
        width: `calc(100vw - ${isExpanded ? 266 : 72}px)`,
        height: "calc(100vh - 144px)"
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const { user } = store.getters["authentication/credentials"];
      const managerId = Number(to.params.managerId);
      const response = await API.board().get(managerId || user.id);
      const managers = await API.manager().getList();

      next(vm => {
        vm.setMangers(managers);
        vm.setBoard(response);

        if (to.params.managerId) {
          vm.currentManager = managerId;
        } else {
          vm.currentManager = user.id;
          vm.$router.replace(`/board/${user.id}`);
        }

        if (to.params.cardId) {
          vm.showCardDetails(Number(to.params.cardId));
        }
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  mounted() {
    this.updateInterval = setInterval(() => {
      this.loadBoard();
    }, 10000);
  },

  methods: {
    setColumnFocus() {
      setTimeout(() => {
        (this.$refs.newColumnName as Vue).focus();
      }, 200);
    },
    async loadBoard() {
      try {
        const response = await this.$API
          .board()
          .get(this.currentManager as number);
        this.setBoard(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    setBoard(response: any) {
      this.items = response;
    },
    setMangers(response: any) {
      this.selects.managers = response;
    },
    async changeCard(e: any, status: string) {
      if (e.hasOwnProperty("removed")) {
        return;
      }

      const item = {
        order: -1 as number,
        id: -1 as number
      };

      if (e.hasOwnProperty("added")) {
        item.order = e.added.newIndex;
        item.id = e.added.element.id;
      }

      if (e.hasOwnProperty("moved")) {
        item.order = e.moved.newIndex;
        item.id = e.moved.element.id;
      }

      const column = this.items.find(item => item.id === status);

      try {
        await this.$API.lead().move(item.id, {
          after: column.leads[item.order - 1]?.id,
          board_status_id: status
        });
        await this.loadBoard();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changeColumn(e: any) {
      if (e.hasOwnProperty("moved")) {
        try {
          const after = this.items[e.moved.newIndex - 1];

          await this.$API.board().edit(e.moved.element.id, {
            after: after?.id || 0
          });
          await this.loadBoard();
        } catch (e) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    },
    async addNewColumn() {
      if (!this.newCol.name) {
        return;
      }

      try {
        await this.$API.board().create({
          ...this.newCol,
          manager_id: this.currentManager
        });
        this.cancelAddColumn();
        await this.loadBoard();
        this.closeEditColumnTitle();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Колонка успешно добавлена!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    cancelAddColumn() {
      this.newColumnPopup = false;
      this.newCol = {
        name: "",
        color: "primary"
      };
    },
    async editColumnName(item: any) {
      try {
        await this.$API.board().edit(item.id, {
          name: this.editedColumn.name
        });
        await this.loadBoard();
        this.closeEditColumnTitle();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async deleteColumn(column: any) {
      if (column.leads.length) {
        await this.$store.dispatch(
          "alert/showError",
          "Невозможно удалить колонку"
        );
        return;
      }

      try {
        await this.$API.board().deleteColumn(column.id);
        await this.reloadBoard();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Колонка успешно удалена!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeEditColumnTitle() {
      this.editedColumn.id = null;
      this.editedColumn.name = "";
    },
    async reloadBoard() {
      await this.loadBoard();
      try {
        await this.$router.push(`/board/${this.currentManager}`);
      } catch {
        await Promise.resolve();
      }
    },
    makeEditable(item: any) {
      this.editedColumn.id = item.id;
      this.editedColumn.name = item.name;

      this.$nextTick(() => {
        const [titleInput] = this.$refs.input as Array<Vue>;
        titleInput.focus();
      });
    },
    async showCardDetails(id: number | null = null) {
      this.openCardId = id;
      this.cardDetailsDialog = true;

      try {
        await this.$router.push(`/board/${this.currentManager}/${id}`);
      } catch {
        await Promise.resolve();
      }
    },
    async addCard(status: number) {
      this.statusForNewCard = status;
      this.cardCreateDialog = true;
    }
  },

  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
});
