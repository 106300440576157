
import Vue from "vue";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "EmailViewer",

  directives: {
    mask
  },

  props: {
    emails: {
      type: Array,
      required: true
    },
    selected: {
      required: true,
      type: String
    },
    lead: {
      required: true,
      type: Number
    }
  },

  data: () => ({
    model: {
      to: "",
      subject: "",
      text: "",
      attachments: [] as Array<any>
    },
    loading: false,
    maxFileSize: 10485760,
    allowedTypes: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "application/msword"
    ],
    message: "",
    dragOver: false as boolean,
    createForm: false as boolean,
    messages: [] as Array<any>
  }),

  watch: {
    selected: {
      immediate: true,
      async handler() {
        this.model.to = this.selected;
      }
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async uploadFile(file: any): Promise<any> {
      if (this.validateFile(file)) {
        this.model.attachments.push({
          file,
          preview: await this.toBase64(file)
        });
      }
    },
    validateFile(file: any): boolean {
      if (file.size > this.maxFileSize) {
        this.$store.dispatch(
          "alert/showError",
          `
        Размер файла ${file.name} больше ${this.maxFileSize / 1024 / 1024}мб.
        `
        );
        return false;
      }
      if (!this.allowedTypes.includes(file.type)) {
        this.$store.dispatch(
          "alert/showError",
          `
        Файла ${file.name} имеет недопустимый тип.
        `
        );
        return false;
      }
      return true;
    },
    getFile(): void {
      const fileInput: any = this.$refs.file;
      const files: Array<File> = fileInput.files;

      if (
        files.length > 3 ||
        this.model.attachments.length + files.length > 3
      ) {
        this.$store.dispatch(
          "alert/showError",
          "Максимально количество загруженных файлов не может быть больше 3х."
        );
        return;
      }

      for (const file of files) {
        if (file) {
          this.uploadFile(file);
        }
      }

      fileInput.value = "";
    },
    pickFile(): void {
      (this.$refs.file as any).click();
    },
    toBase64(file: File): Promise<any> {
      return new Promise(resolve => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    },
    deleteFile(index: number): void {
      this.model.attachments.splice(index, 1);
    },
    drop(event: any): void {
      event.preventDefault();
      (this.$refs.file as any).files = event.dataTransfer.files;
      this.getFile();
      this.dragOver = false;
    },
    dragover(event: any): void {
      event.preventDefault();
      this.dragOver = true;
    },
    async pasteFile(e: any) {
      if (
        e.clipboardData.files.length > 3 ||
        this.model.attachments.length + e.clipboardData.files.length > 3
      ) {
        await this.$store.dispatch(
          "alert/showError",
          "Максимально количество загруженных файлов не может быть больше 3х."
        );
        return;
      }

      if (e.clipboardData.files.length) {
        for (const file of [...e.clipboardData.files]) {
          await this.uploadFile(file);
        }
      }
    },
    dragleave(): void {
      this.dragOver = false;
    },
    async loadData() {
      try {
        this.messages = await this.$API.lead().getEmails(this.lead);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async sendEmail() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        const model = { ...this.model };

        model.attachments = model.attachments.map(({ file }) => file);

        await this.$API.lead().sendEmail(this.lead, model);
        await this.loadData();
        this.toggleCreateForm();
        this.model.subject = "";
        this.model.attachments = [];
        this.model.text = "";
        await this.$store.dispatch(
          "alert/showSuccess",
          "Сообщиние успешно отправлено!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }

      this.loading = false;
    },
    downloadFile(file: any) {
      const a = document.createElement("a");
      a.setAttribute("href", file.url);
      a.setAttribute("target", "_blank");
      a.click();
    },
    toggleCreateForm() {
      this.createForm = !this.createForm;

      if (this.createForm) {
        setTimeout(() => {
          (this.$refs.subject as Vue).focus();
        }, 300);
      }
    }
  }
});
