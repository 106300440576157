
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import moment from "moment";

export default Vue.extend({
  name: "SubscriptionRenew",

  components: { DatePicker },

  props: {
    lead: {
      required: true,
      type: Number
    },
    subscription: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    model: {
      starts_at: moment().format("YYYY-MM-DD"),
      ends_at: moment()
        .add("months", 1)
        .format("YYYY-MM-DD")
    } as any,
    plans: [] as Array<any>,
    errorMessages: {} as any,
    loading: false as boolean
  }),

  watch: {
    subscription: {
      immediate: true,
      handler() {
        this.setDate();
      }
    },
    "model.starts_at"() {
      this.model.ends_at = moment(this.model.starts_at)
        .add("months", 1)
        .format("YYYY-MM-DD");
    }
  },

  computed: {
    minDate() {
      return moment().format("YYYY-MM-DD");
    },
    rules() {
      return {
        required: (v: string): boolean | string =>
          !!v || "Поле обязательно для заполнения!"
      };
    },


  },

  async mounted() {
    await this.getPlans();
  },

  methods: {
    setDate(): void {
      if (this.subscription?.period) {
        this.model.ends_at = this.$moment(
          this.subscription.period.end * 1000
        ).format("YYYY-MM-DD");
        this.model.starts_at = this.$moment(
          this.subscription.period.start * 1000
        ).format("YYYY-MM-DD");
        this.model.plan_id = this.subscription.plan_id;
      }
    },
    async renewSubscription() {
      if ((this.$refs.form as any).validate()) {
        this.loading = true;
        try {
          const response = await this.$API
            .lead()
            .extendSubscription(Number(this.lead), this.model);
          this.$emit("change", response);
          await this.$store.dispatch(
            "alert/showSuccess",
            "Тариф успешно обновлён"
          );
        } catch (e) {
          if (e.hasOwnProperty("errors")) {
            this.errorMessages = e.errors;
            setTimeout(() => {
              this.errorMessages = {};
            }, 3000);
          }
          await this.$store.dispatch("alert/showError", e.message);
        }
        this.loading = false;
      }
    },
    async getPlans() {
      this.loading = true;
      try {
        let plans = await this.$API.billing().getPlansList();
        this.plans = plans.filter((item: any)=>{
          return item.alias.includes('new');
        })
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    }
  }
});
