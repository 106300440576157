
import Vue from "vue";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "CardCreate",

  directives: {
    mask
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    managers: {
      type: Array,
      required: true
    },
    status: {
      required: true
    },
    manager: {
      required: true
    },
    statuses: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    sources: {
      site: {
        icon: "web",
        color: "primary"
      },
      facebook: {
        icon: "facebook",
        color: "blue"
      }
    },
    model: {
      source: "site",
      contacts: {
        phones: [],
        emails: []
      }
    } as any,
    dialog: false as boolean,
    rules: {
      required: (v: string): boolean | string =>
        !!v || "Поле обязательно для заполнения!",
      email: (v: string): boolean | string =>
        /.+@.+\..+/.test(v) || "Неправильный формат почты",
      phone: (v: string): boolean | string =>
        !v || v.length == 12 || "Неправильный формат телефона"
    },
    errorMessages: {}
  }),

  watch: {
    value: {
      immediate: true,
      async handler() {
        this.model.manager_id = this.manager;
        this.model.board_status_id = this.status;
        this.dialog = this.value;
      }
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("input", this.dialog);
      this.$emit("close");
      this.model = {
        source: "site",
        contacts: {
          phones: [],
          emails: []
        }
      };
    },
    addContact(key: string) {
      this.model.contacts[key].push("");

      this.$nextTick(() => {
        const items = this.$refs.phones as any;
        items[items.length - 1].focus();
      });
    },
    removeContact(key: string, index: number) {
      this.model.contacts[key].splice(index, 1);
    },
    async createItem(open = false) {
      if ((this.$refs.form as any).validate()) {
        try {
          const response = await this.$API.lead().create(this.model);
          if (open) {
            this.$emit("closeWithOpen", response.id);
          }
          this.closeDialog();
          await this.$store.dispatch(
            "alert/showSuccess",
            "Карточка успешно добавлена!"
          );
        } catch (e) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    }
  }
});
