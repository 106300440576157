
import Vue from "vue";
import WaveSurfer from "wavesurfer.js";

export default Vue.extend({
  name: "AudioPlayer",

  props: {
    file: {
      type: String,
      required: true
    },
    waveColor: {
      type: String,
      required: false,
      default: "#d7d7d7"
    },
    cursorColor: {
      type: String,
      required: false,
      default: "#ffffff"
    },
    progressColor: {
      type: String,
      required: false,
      default: "#ffffff"
    }
  },

  data: () => ({
    playerId: `player_${(Math.random() * 10000000).toFixed(0)}`,
    player: null as any,
    recordTime: 0 as number,
    play: false as boolean
  }),

  computed: {
    getRecordTime() {
      if (!this.recordTime) {
        return "00:00";
      }
      if (this.recordTime < 60) {
        return `00:${this.recordTime.toString().padStart(2, "0")}`;
      }
      const minutes = ((this.recordTime as number) / 60).toFixed(0);
      const seconds = ((this.recordTime as number) % 60).toFixed(0);
      return `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
    }
  },

  beforeMount() {
    (this as any).initPlayer();
  },

  methods: {
    initPlayer() {
      this.$nextTick(() => {
        this.player = WaveSurfer.create({
          container: this.$refs.player as any,
          waveColor: this.waveColor,
          cursorColor: this.cursorColor,
          progressColor: this.progressColor,
          barWidth: 2,
          barRadius: 1,
          cursorWidth: 1,
          height: 25,
          barGap: 2
        });

        this.player.on("finish", () => {
          this.setPause();
          this.recordTime = Math.floor(this.player.getDuration());
        });

        this.player.on("ready", () => {
          this.recordTime = Math.floor(this.player.getDuration());
        });

        this.player.on("audioprocess", (e: any) => {
          this.recordTime = Math.floor(e);
        });

        this.player.load(this.file);
      });
    },
    setPause() {
      this.play = false;
      this.player.pause();
    },
    setPlay() {
      this.play = true;
      this.player.play();
    }
  }
});
