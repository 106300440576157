
import Vue from "vue";
import Rooms from "@/components/chat/components/Rooms.vue";
import GroupRoom from "@/components/chat/components/GroupRoom.vue";
import PeopleRoom from "@/components/chat/components/PeopleRoom.vue";
import CreateRoom from "@/components/chat/components/CreateRoom.vue";

export default Vue.extend({
  name: "Chat",

  components: { CreateRoom, PeopleRoom, GroupRoom, Rooms },

  props: {
    fullMode: {
      type: Boolean,
      default: false,
      required: false
    },
    hideRooms: {
      type: Boolean,
      default: false,
      required: false
    },
    roomId: {
      type: String,
      default: null,
      required: false
    },
    hideClose: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    rooms: [] as Array<any>,
    messages: [] as Array<any>,
    room: {} as any,
    showRooms: true as boolean,
    menu: false as boolean,
    emptyRooms: false as boolean,
    showPeopleChat: false as boolean,
    showGroupChat: false as boolean,
    updateTimeout: null as any,
    updateRoomTimeout: null as any,
    updateInterval: null as any,
    showCreateRoomDialog: false as boolean,
    deadChat: false as boolean,

  }),

  async mounted() {
    if (!this.hideRooms) {
      await this.loadRooms();
    }
    if (this.roomId) {
      await this.openPeopleChat(this.roomId);
    }
    this.listenRoomEvents();

    this.updateInterval = setInterval(async () => {
      const response = await this.$API
          .messenger()
          .getRoomMessages(this.room.id);

      this.messages = response.map((item: any) => ({
        ...item,
        _show: true,
        _isOwner:
            item.user_id ===
            this.$store.getters["authentication/credentials"].user.id,
        _editable:
            !item.files || !item.files.some((el: any) => el.type === "audio")
      }));
    }, 5000);
  },

  methods: {
    listenRoomEvents() {
      try {
        this.$Pusher
          .instance()
          .subscribe(
            `Messenger.state.${this.$store.getters["authentication/credentials"].user.id}`
          )
          .bind("App\\Events\\Messenger\\MessengerStateUpdated", () => {
            this.loadRooms();
          });
      } catch (e) {
        this.deadChat = true;
        console.error(e);
      }
    },
    async openPeopleChat(room: any) {
      if (!room) {
        this.backToRooms();
        return;
      }

      try {
        this.messages = [];
        this.room = await this.$API.messenger().getRoom(room);
        const response = await this.$API
          .messenger()
          .getRoomMessages(this.room.id);

        this.messages = response.map((item: any) => ({
          ...item,
          _show: true,
          _isOwner:
            item.user_id ===
            this.$store.getters["authentication/credentials"].user.id,
          _editable:
            !item.files || !item.files.some((el: any) => el.type === "audio")
        }));

        this.showPeopleChat = true;
        this.showRooms = false;

        this.$emit("loadRoom");
      } catch (e) {
        this.$emit("loadError");
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadRooms() {
      try {
        this.rooms = await this.$API.messenger().getRooms();

        if (this.$can("openFirst", "Chat:rooms") && !this.roomId) {
          if (!this.rooms.length) {
            this.emptyRooms = true;
            this.closeChat();
            return;
          }

          const [room] = this.rooms;

          if (room && this.room.name !== room.name) {
            this.$nextTick(async () => {
              await this.openPeopleChat(room.id);
            });
          }
        }

        this.$emit("loadRooms");
      } catch (e) {
        this.$emit("loadError");
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    openGroupChat() {
      this.showGroupChat = true;
      this.showRooms = false;
      this.$nextTick(() => {
        (this.$refs.room as any).initChat();
      });
    },
    openCreateRoomDialog() {
      this.showCreateRoomDialog = true;
      this.showRooms = false;
    },
    closeChat() {
      this.menu = false;
    },
    backToRooms() {
      this.showPeopleChat = false;
      this.showGroupChat = false;
      this.showCreateRoomDialog = false;
      this.showRooms = true;
    },
    openMenu() {
      // console.log("open");
    }
  },

  beforeDestroy() {
    try {
      clearInterval(this.updateInterval);

      this.$Pusher
        .instance()
        .unsubscribe(
          `Notifications.${this.$store.getters["authentication/credentials"].user.id}`
        );
    } catch (e) {
      console.error(e);
    }
  }
});
