
import Vue from "vue";
import Chat from "@/components/chat/Chat.vue";

export default Vue.extend({
  name: "ChatViewer",

  components: { Chat },

  props: {
    lead: {
      required: true,
      type: String
    }
  }
});
