
import Vue from "vue";
import debounce from "lodash/fp/debounce";
import AudioPlayer from "@/components/media/AudioPlayer.vue";

export default Vue.extend({
  name: "MessageInput",
  components: { AudioPlayer },
  props: {
    editMode: {
      default: false,
      required: false,
      type: Boolean
    },
    editedItem: {
      required: false,
      type: Object
    },
    room: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    smiles: [
      "😀",
      "😁",
      "😂",
      "😃",
      "😄",
      "🤣",
      "😅",
      "😆",
      "😇",
      "😉",
      "😊",
      "🙂",
      "🙃",
      "☺",
      "😋",
      "😌",
      "😍",
      "🥰",
      "😘",
      "😗",
      "😙",
      "😚",
      "🤪",
      "😜",
      "😝",
      "😛",
      "🤑",
      "😎",
      "🤓",
      "🧐",
      "🤠",
      "🥳",
      "🤗",
      "🤡",
      "😏",
      "😶",
      "😐",
      "😑",
      "😒",
      "🙄",
      "🤨",
      "🤔",
      "🤫",
      "🤭",
      "🤥",
      "😳",
      "😞",
      "😟",
      "😠",
      "😡",
      "🤬",
      "😔",
      "😕",
      "🙁",
      "☹",
      "😬",
      "🥺",
      "😣",
      "😖",
      "😫",
      "😩",
      "🥱",
      "😤",
      "😮",
      "😱",
      "😨",
      "😰",
      "😯",
      "😦",
      "😧",
      "😢",
      "😥",
      "😪",
      "🤤",
      "😓",
      "😭",
      "🤩",
      "😵",
      "🥴",
      "😲",
      "🤯",
      "🤐",
      "😷",
      "🤕",
      "🤒",
      "🤮",
      "🤢",
      "🤧",
      "🥵",
      "🥶",
      "😴",
      "💤",
      "😈",
      "👿",
      "👹",
      "👺",
      "💩",
      "👻",
      "💀",
      "☠",
      "👽",
      "🤖",
      "🎃",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "😿",
      "😾",
      "👐",
      "🤲",
      "🙌",
      "👏",
      "🙏",
      "🤝",
      "👍",
      "👎",
      "👊",
      "✊",
      "🤛",
      "🤜",
      "🤞",
      "✌",
      "🤘",
      "🤟",
      "👌",
      "🤏",
      "👈",
      "👉",
      "👆",
      "👇",
      "☝",
      "✋",
      "🤚",
      "🖐",
      "🖖",
      "👋",
      "🤙",
      "💪",
      "🦾",
      "🖕",
      "✍",
      "🤳",
      "💅",
      "🦵",
      "🦿",
      "🦶",
      "👄",
      "🦷",
      "👅",
      "👂",
      "🦻",
      "👃",
      "👁",
      "👀",
      "🧠",
      "🦴",
      "👤",
      "👥",
      "🗣",
      "👶",
      "👧",
      "🧒",
      "👦",
      "👩",
      "🧑",
      "👨",
      "👩‍🦱",
      "👨‍🦱",
      "👩‍🦰",
      "👨‍🦰",
      "👱‍♀️",
      "👱",
      "👱‍♂️",
      "👩‍🦳",
      "👨‍🦳",
      "👩‍🦲",
      "👨‍🦲",
      "🧔",
      "👵",
      "🧓",
      "👴",
      "👲",
      "👳‍♀️",
      "👳",
      "👳‍♂️",
      "🧕",
      "👮‍♀️",
      "👮",
      "👮‍♂️",
      "👩‍🚒",
      "👨‍🚒",
      "👷‍♀️",
      "👷",
      "👷‍♂️",
      "👩‍🏭",
      "👨‍🏭",
      "👩‍🔧",
      "👨‍🔧",
      "👩‍🌾",
      "👨‍🌾",
      "👩‍🍳",
      "👨‍🍳",
      "👩‍🎤",
      "👨‍🎤",
      "👩‍🎨",
      "👨‍🎨",
      "👩‍🏫",
      "👨‍🏫",
      "👩‍🎓",
      "👨‍🎓",
      "👩‍💼",
      "👨‍💼",
      "👩‍💻",
      "👨‍💻",
      "👩‍🔬",
      "👨‍🔬",
      "👩‍🚀",
      "👨‍🚀",
      "👩‍⚕️",
      "👨‍⚕️",
      "👩‍⚖️",
      "👨‍⚖️",
      "👩‍✈️",
      "👨‍✈️",
      "💂‍♀️",
      "💂",
      "💂‍♂️",
      "🕵️‍♀️",
      "🕵",
      "🕵️‍♂️",
      "🤶",
      "🎅",
      "👼",
      "👸",
      "🤴",
      "👰",
      "🤵",
      "🕴️‍♀️",
      "🕴",
      "🦸‍♀️",
      "🦸",
      "🦸‍♂️",
      "🦹‍♀️",
      "🦹",
      "🦹‍♂️",
      "🧙‍♀️",
      "🧙",
      "🧙‍♂️",
      "🧝‍♀️",
      "🧝",
      "🧝‍♂️",
      "🧚‍♀️",
      "🧚",
      "🧚‍♂️",
      "🧞‍♀️",
      "🧞",
      "🧞‍♂️",
      "🧜‍♀️",
      "🧜",
      "🧜‍♂️",
      "🧛‍♀️",
      "🧛",
      "🧛‍♂️",
      "🧟‍♀️",
      "🧟",
      "🧟‍♂️",
      "🙇‍♀️",
      "🙇",
      "🙇‍♂️",
      "💁‍♀️",
      "💁",
      "💁‍♂️",
      "🙅‍♀️",
      "🙅",
      "🙅‍♂️",
      "🙆‍♀️",
      "🙆",
      "🙆‍♂️",
      "🤷‍♀️",
      "🤷",
      "🤷‍♂️",
      "🙋‍♀️",
      "🙋",
      "🙋‍♂️",
      "🤦‍♀️",
      "🤦",
      "🤦‍♂️",
      "🧏‍♀️",
      "🧏",
      "🧏‍♂️",
      "🙎‍♀️",
      "🙎",
      "🙎‍♂️",
      "🙍‍♀️",
      "🙍",
      "🙍‍♂️",
      "💇‍♀️",
      "💇",
      "💇‍♂️",
      "💆‍♀️",
      "💆",
      "💆‍♂️",
      "🤰",
      "🤱",
      "🧎‍♀️",
      "🧎",
      "🧎‍♂️",
      "🧍‍♀️",
      "🧍",
      "🧍‍♂️",
      "🚶‍♀️",
      "🚶",
      "🚶‍♂️",
      "👩‍🦯",
      "👨‍🦯",
      "🏃‍♀️",
      "🏃",
      "🏃‍♂️",
      "👩‍🦼",
      "👨‍🦼",
      "👩‍🦽",
      "👨‍🦽",
      "💃",
      "🕺",
      "👯‍♀️",
      "👯",
      "👯‍♂️",
      "👫",
      "🧑‍🤝‍🧑",
      "👩‍❤️‍👨",
      "💑",
      "👩‍❤️‍💋‍👨",
      "💏",
      "👪",
      "👨‍👩‍👧",
      "👩‍👦",
      "👩‍👧",
      "👩‍👧‍👦",
      "👩‍👦‍👦",
      "👩‍👧‍👧",
      "👨‍👦",
      "👨‍👧",
      "👨‍👧‍👦",
      "👨‍👦‍👦",
      "👨‍👧‍👧",
      "👚",
      "👕",
      "🥼",
      "🦺",
      "🧥",
      "👖",
      "👔",
      "👗",
      "👘",
      "🥻",
      "🩱",
      "👙",
      "🩲",
      "🩳",
      "💄",
      "💋",
      "👣",
      "🧦",
      "👠",
      "👡",
      "👢",
      "🥿",
      "👞",
      "👟",
      "🩰",
      "🥾",
      "🧢",
      "👒",
      "🎩",
      "🎓",
      "👑",
      "⛑",
      "🎒",
      "👝",
      "👛",
      "👜",
      "💼",
      "👓",
      "🕶",
      "🥽",
      "🧣",
      "🧤",
      "💍",
      "🌂",
      "☂"
    ],
    debounce,
    debounced: null as any,
    maxFileSize: 10485760,
    allowedTypes: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "application/msword"
    ],
    message: "",
    dragOver: false as boolean,
    files: [] as Array<any>,
    audioFile: null as any,
    audioPoints: [] as Array<number>,
    audioRecorder: null as any,
    audioChunks: [] as Array<any>,
    recordTime: 0 as number,
    loading: false as boolean,
    audioMenu: false as boolean
  }),

  watch: {
    editedItem() {
      if (this.editedItem) {
        this.message = this.editedItem.content;
        this.files = [];
        this.setFocus();
      }
    }
  },

  computed: {
    getRecordTime() {
      if (!this.recordTime) {
        return "00:00";
      }
      if (this.recordTime < 60) {
        return `00:${this.recordTime.toString().padStart(2, "0")}`;
      }
      const minutes = (this.recordTime / 60).toFixed(0);
      const seconds = (this.recordTime % 60).toFixed(0);
      return `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
    }
  },

  mounted() {
    this.setFocus();
  },

  methods: {
    setSize() {
      const messagesContainer = document.getElementById("messages-container")!;
      const cardBody = document.getElementById("card-body")!;
      const bodyHeight = document.body.offsetHeight;
      const marginY = Math.trunc(bodyHeight * 0.1);
      const calculatedHeight = bodyHeight - 128 - 72 - 70;
      const cardAsideChat = document.getElementById("card-aside-chat")!;

      if (this.$vuetify.breakpoint.smAndDown) {
        messagesContainer.style.maxHeight = `${calculatedHeight + 10}px`;
        // messagesContainer.style.height = `${calculatedHeight + 10}px`;
        if (cardBody) {
          cardBody.style.maxHeight = `${bodyHeight - 128}px`;
        }
        if (cardAsideChat) {
          cardAsideChat.style.height = `${bodyHeight - 122}px`;
        }
      } else {
        messagesContainer.style.maxHeight = `${calculatedHeight - marginY}px`;
        if (cardAsideChat) {
          cardAsideChat.style.height = `inherit`;
        }
        if (cardBody) {
          cardBody.style.maxHeight = `${bodyHeight - 137 - marginY}px`;
        }
      }
    },
    setFocus() {
      this.$nextTick(() => {
        (this.$refs.messageInput as any).focus();
      });
    },
    closeEditable() {
      this.$emit("closeEdit");
      this.message = "";
      this.files = [];
      this.setFocus();
    },
    async updateMessage(message: any) {
      if (
        (!this.message && !this.files.length && !this.audioFile) ||
        this.loading
      ) {
        return;
      }

      this.loading = true;
      try {
        await this.$API.messenger().updateMessage(message.id, {
          content: this.message,
          attachments: this.files.map((item: any) => item.file)
        });
        this.closeEditable();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async sendMessage() {

      this.message = this.message.trim();

      if (
        (!this.message && !this.files.length && !this.audioFile) ||
        this.loading
      ) {
        return;
      }

      console.log(this.message)

      this.loading = true;
      try {
        const model = {
          content: this.message,
          attachments: this.files.map((item: any) => item.file)
        };

        if (this.audioFile) {
          model.attachments = [this.audioFile.file];
        }

        let response = await this.$API.messenger().sendMessage(this.room.member_id, model);
        this.$emit('messageSent', response[0]);

        this.files = [];
        this.message = "";
        this.audioFile = null;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
      this.setFocus();
    },
    async uploadFile(file: any): Promise<any> {
      if (this.validateFile(file)) {
        this.files.push({
          file,
          preview: await this.toBase64(file)
        });
      }
    },
    validateFile(file: any): boolean {
      if (file.size > this.maxFileSize) {
        this.$store.dispatch(
          "alert/showError",
          `
        The file size of ${file.name} is larger than ${this.maxFileSize / 1024 / 1024}MB.
        `
        );
        return false;
      }
      if (!this.allowedTypes.includes(file.type)) {
        this.$store.dispatch(
          "alert/showError",
          `
        The file ${file.name} is not a valid file type.
        `
        );
        return false;
      }
      return true;
    },
    getFile(): void {
      const fileInput: any = this.$refs.file;
      const files: Array<File> = fileInput.files;

      if (files.length > 3 || this.files.length + files.length > 3) {
        this.$store.dispatch(
          "alert/showError",
          "The maximum number of uploaded files cannot exceed 3."
        );
        return;
      }

      for (const file of files) {
        if (file) {
          this.uploadFile(file);
        }
      }

      fileInput.value = "";
    },
    pickFile(): void {
      (this.$refs.file as any).click();
    },
    toBase64(file: File): Promise<any> {
      return new Promise(resolve => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    },
    closeDialog(): void {
      this.$emit("close", this.model);
    },
    deleteFile(index: number): void {
      this.files.splice(index, 1);
    },
    drop(event: any): void {
      event.preventDefault();
      (this.$refs.file as any).files = event.dataTransfer.files;
      this.getFile();
      this.dragOver = false;
    },
    dragover(event: any): void {
      event.preventDefault();
      this.dragOver = true;
    },
    async pasteFile(e: any) {
      if (
        e.clipboardData.files.length > 3 ||
        this.files.length + e.clipboardData.files.length > 3
      ) {
        this.$store.dispatch(
          "alert/showError",
          "The maximum number of uploaded files cannot exceed 3."
        );
        return;
      }

      if (e.clipboardData.files.length) {
        for (const file of [...e.clipboardData.files]) {
          await this.uploadFile(file);
        }
      }
    },
    dragleave(): void {
      this.dragOver = false;
    },
    async getUserMedia() {
      try {
        const arrayBuffer = new Uint8Array(256);
        const context = new AudioContext();
        const analyser = context.createAnalyser();
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        const src = context.createMediaStreamSource(stream);
        //@ts-ignore
        this.audioRecorder = new MediaRecorder(stream);
        const readAudio = () => {
          if (!this.audioMenu) {
            return;
          }

          window.requestAnimationFrame(readAudio);
          analyser.getByteFrequencyData(arrayBuffer);
          this.audioPoints = [...arrayBuffer];
        };

        this.audioRecorder.addEventListener("dataavailable", (e: any) => {
          if (e.data.size > 0) {
            this.audioChunks.push(e.data);
          }
        });

        src.connect(analyser);
        this.showAudioRecord();
        readAudio();
        this.audioRecorder?.start();

        this.audioTimeout = setInterval(() => {
          this.recordTime++;
        }, 1000);
      } catch (e) {
        await this.$store.dispatch("alert/showError", {
          text:
            "The microphone could not be accessed. Please check your browser settings.",
          enableSound: true
        });
      }
    },
    async sendRecordedMessage() {
      if (this.audioRecorder) {
        this.audioRecorder.addEventListener("stop", async () => {
          const blob = new Blob(this.audioChunks, {
            type: "audio/ogg; codecs=opus"
          });
          const file = new File([blob], "audio", {
            type: "audio/ogg; codecs=opus"
          });
          this.audioFile = {
            file,
            preview: await this.toBase64(file)
          };
          await this.sendMessage();
        });

        this.audioRecorder.stop();
        this.closeAudioRecord();
      } else {
        await this.sendMessage();
      }
      this.closeAudioMenu();
    },
    showAudioMessagePreview() {
      this.audioRecorder.addEventListener("stop", async () => {
        const blob = new Blob(this.audioChunks, {
          type: "audio/ogg; codecs=opus"
        });
        const file = new File([blob], "audio", {
          type: "audio/ogg; codecs=opus"
        });
        this.audioFile = {
          file,
          preview: await this.toBase64(file)
        };
      });

      this.audioRecorder.stop();
      this.closeAudioRecord();
    },
    async deleteMessage() {
      try {
        await this.$API.messenger().deleteMessage(this.editedItem.id);
        this.closeEditable();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeAudioRecord() {
      if (this.audioRecorder && this.audioRecorder.state !== "inactive") {
        this.audioRecorder?.stop();
      }
      this.audioPoints = [];
      this.audioRecorder = null;
      this.audioChunks = [];
      clearInterval(this.audioTimeout);
      this.recordTime = 0;
    },
    showAudioRecord() {
      this.audioMenu = true;
    },
    closeAudioMenu() {
      this.audioMenu = false;
      this.audioFile = null;
    }
  }
});
