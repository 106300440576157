
import Vue from "vue";

export default Vue.extend({
  name: "GroupRoom",

  props: {
    hideBack: {
      default: false,
      required: false,
      type: Boolean
    },
    room: {
      required: true,
      type: Object
    },
    initialMessages: {
      required: true,
      type: Array
    }
  },

  data: () => ({
    messages: [] as Array<any>,
    tab: "chat" as string,
    message: "" as string
  }),

  methods: {
    initChat() {
      this.$nextTick(() => {
        (this.$refs.messageInput as any).focus();
      });
      this.scrollToBottom();
    },
    getMessageTime(time: number) {
      return new Date(time * 1000)
        .toLocaleTimeString("en-GB", {
          timeZone: "Europe/Chisinau"
        })
        .substring(0, 5);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messages as any;
        container.scrollTop = container.scrollHeight;
      });
    },
    sendMessage() {
      if (!this.message) {
        return;
      }

      this.messages.push({
        sender_id: 2,
        owner: true,
        text: this.message,
        read_at: "",
        created_at: new Date().getTime() / 1000
      });
      this.message = "";

      this.scrollToBottom();

      setTimeout(() => {
        this.messages.push({
          sender_id: 1,
          owner: false,
          text: "Тестовый ответ :)",
          read_at: "",
          created_at: new Date().getTime() / 1000
        });
        this.scrollToBottom();
      }, 4000);
    }
  }
});
