
import { getFormattedTime } from "@/services/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",

  props: {
    rules: {
      required: false,
      type: Array,
      default: () => []
    },
    value: {
      required: false,
      type: String
    },
    label: {
      required: false,
      default: "",
      type: String
    },
    min: {
      required: false,
      default: new Date("1970-01-01").toISOString(),
      type: String
    },
    max: {
      required: false,
      default: new Date("2100-01-01").toISOString(),
      type: String
    },
    hideDetails: {
      required: false,
      default: false,
      type: Boolean
    },
    errorMessages: {
      required: false,
      default: () => [],
      type: Array
    }
  },

  data: () => ({
    getFormattedTime: getFormattedTime as Function,
    date: "" as number | string | Date,
    menu: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.date = this.value as any;
        } else {
          this.dates = "";
        }
      }
    }
  },

  methods: {
    changeData(): void {
      this.$emit("input", this.date);
      this.menu = false;
    },
    close(): void {
      this.date = "";
      this.changeData();
      this.menu = false;
    },
    toggle() {
      this.menu = !this.menu;
    },
    clearDate(): void {
      this.date = "";
      this.changeData();
    }
  }
});
