
import Vue from "vue";
import { mask } from "vue-the-mask";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "DocumentViewer",
  components: { FileUploader },
  directives: {
    mask
  },

  props: {
    documents: {
      type: Array,
      required: true
    },
    lead: {
      required: true,
      type: Number
    },
    user: {
      required: true,
      type: Number
    }
  },

  data: () => ({
    document: {} as any,
    errorMessages: {} as any,
    createForm: false as boolean,
    loading: false as boolean
  }),

  computed: {
    maxUploadDocuments() {
      return this.documents.length < 100;
    }
  },

  methods: {
    async deleteDocument(document: any): Promise<void> {
      try {
        await this.$API.user().deleteDocument(document.id);

        await this.$store.dispatch(
          "alert/showSuccess",
          "Документ успешно удален!"
        );

        this.$emit("delete", document.id);
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
        }
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    async addDocument() {
      try {
        this.loading = true;
        const response = await this.$API
          .user()
          .uploadUserDocuments(this.document, this.user);

        this.documents.push(response);
        this.createForm = false;
        this.document = { title: "", document: null };

        this.loading = false;

        await this.$store.dispatch(
          "alert/showSuccess",
          "Документ успешно добавлен"
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
        }
        this.loading = false;
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    getSize(size: number): string {
      let formattedSize = "";
      if (size < 1000000) {
        formattedSize = `${Math.floor(size / 10) / 100} KB`;
      } else {
        formattedSize = `${Math.floor(size / 10000) / 100} MB`;
      }
      return formattedSize;
    },
    toggleCreateForm() {
      this.createForm = !this.createForm;

      if (this.createForm) {
        setTimeout(() => {
          (this.$refs.subject as Vue).focus();
        }, 300);
      }
    }
  }
});
