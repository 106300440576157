
import Vue from "vue";
import { mask } from "vue-the-mask";
import EmailViewer from "@/modules/board/components/EmailViewer.vue";
import DocumentViewer from "@/modules/board/components/DocumentViewer.vue";
import ChatViewer from "@/modules/board/components/Сhat.vue";
import SubscriptionRenew from "@/modules/board/components/SubscriptionRenew.vue";
import API from '@/api/API';
import store from '@/store';

export default Vue.extend({
  name: "CardDetails",

  components: { SubscriptionRenew, ChatViewer, DocumentViewer, EmailViewer },

  directives: {
    mask,
    dynamicHeight: {
      bind(el, binding, vnode) {
        const resizeContainer = () => {
          if (vnode.context?.$vuetify.breakpoint.smAndDown) {
            el.style.height = `calc(100vh - ${el.previousElementSibling?.clientHeight}px)`;
          } else {
            el.style.height = `calc(80vh - ${el.previousElementSibling?.clientHeight}px)`;
          }
        };

        vnode.context?.$nextTick(() => {
          resizeContainer();

          window.addEventListener("resize", () => {
            resizeContainer();
          });
        });
      }
    }
  },

  props: {
    id: {
      required: false,
      type: Number,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    },
    managers: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    item: {} as any,
    statuses: [] as Array<SelectComponentInterface>,
    selectedEmail: "" as string,
    cancelSubscription: false as boolean,
    sources: {
      site: {
        icon: "web",
        color: "primary"
      },
      facebook: {
        icon: "facebook",
        color: "blue"
      }
    },
    editMode: false as boolean,
    isAdmin: false as boolean,
    editedItem: {} as any,
    dialog: false as boolean,
    selectedGroup: "" as string,
    loading: false as boolean,
    createMode: false as boolean,
    showAside: {
      emails: false,
      chat: false,
      documents: false,
      subscription: false
    } as any,
    rules: {
      required: (v: string): boolean | string =>
        !!v || "Поле обязательно для заполнения!",
      email: (v: string): boolean | string =>
        /.+@.+\..+/.test(v) || "Неправильный формат почты",
      phone: (v: string): boolean | string =>
        !v || v.length == 12 || "Неправильный формат телефона"
    },
    errorMessages: {}
  }),

  watch: {
    "item.board_status_id"(val, oldVal) {
      if (oldVal) {
        this.changeStatus(val, oldVal);
      }
    },
    "item.manager.id"(val, oldVal) {
      if (oldVal) {
        this.changeManager(val, oldVal);
      }
    },
    value: {
      immediate: true,
      async handler() {
        if (this.value) {
          if (this.id) {
            await this.loadData();
            await this.loadStatuses();
          } else {
            this.createMode = true;
          }
        }
        this.dialog = this.value;
      }
    }
  },
  async mounted() {
    this.getCurrentUserRole();
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$API.lead().get(this.id);

        response.contacts = {
          emails: [],
          phones: [],
          ...response.contacts
        };

        response.contacts = {
          emails: response.contacts.emails.map((item: any) => ({
            value: item,
            old: true
          })),
          phones: response.contacts.phones.map((item: any) => ({
            value: item,
            old: true
          }))
        };

        this.item = response;
        this.loading = true;
        await this.loadStatuses();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
        this.closeDialog();
      }
    },
    async loadStatuses() {
      try {
        this.statuses = await this.$API
          .manager()
          .getStatuses(this.item.manager.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async confirmUser() {
      try {
        await this.$API.user().confirmUser(this.item.verification.user_id);
        await this.loadData();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Документы успешно подтверждены!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async rejectUser() {
      try {
        await this.$API.user().rejectUser(this.item.verification.user_id);
        await this.loadData();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Документы успешно отклонены!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.createMode = false;
      this.closeEditable();
      this.closeAside("emails");
      this.closeAside("documents");
      this.closeAside("subscription");
      this.closeAside("chat");
      this.$emit("input", this.dialog);
      this.$emit("close");
    },
    makeEditable() {
      this.editedItem = JSON.parse(JSON.stringify(this.item));
      this.editMode = true;

      this.$nextTick(() => {
        (this.$refs.nameInput as any).focus();
      });
    },
    addContact(key: string) {
      this.editedItem.contacts[key].push({
        value: "",
        old: false
      });

      this.$nextTick(() => {
        const items = this.$refs.phones as any;
        items[items.length - 1].focus();
      });
    },
    removeContact(key: string, index: number) {
      this.editedItem.contacts[key].splice(index, 1);
    },
    async changeStatus(value: string, oldValue: number) {
      try {
        await this.$API.lead().edit(this.item.id, {
          board_status_id: value
        });

        this.$emit("update");
      } catch (e) {
        this.item.board_status_id = oldValue;
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changeManager(value: number, oldValue: number) {
      try {
        const response = await this.$API
          .lead()
          .changeManger(this.item.id, value);

        response.contacts = {
          emails: [],
          phones: [],
          ...response.contacts
        };

        response.contacts = {
          emails: response.contacts.emails.map((item: any) => ({
            value: item,
            old: true
          })),
          phones: response.contacts.phones.map((item: any) => ({
            value: item,
            old: true
          }))
        };

        this.item = response;

        await this.loadStatuses();
        for (const item in this.showAside) {
          this.showAside[item] = false;
        }
        this.$emit("update");
      } catch (e) {
        this.item.manager.id = oldValue;
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async editItem() {
      if ((this.$refs.form as any).validate()) {
        try {
          const model = JSON.parse(JSON.stringify(this.editedItem));

          model.manager_id = model.manager.id;
          model.contacts.emails = model.contacts.emails.map(
            (item: any) => item.value
          );
          model.contacts.phones = model.contacts.phones.map(
            (item: any) => item.value
          );
          delete model.manager;

          const response = await this.$API.lead().edit(this.item.id, model);

          response.contacts = {
            emails: [],
            phones: [],
            ...response.contacts
          };

          response.contacts = {
            emails: response.contacts.emails.map((item: any) => ({
              value: item,
              old: true
            })),
            phones: response.contacts.phones.map((item: any) => ({
              value: item,
              old: true
            }))
          };

          this.closeEditable();
          this.item = response;
          await this.$store.dispatch(
            "alert/showSuccess",
            "Карточка успешно обновлена!"
          );
        } catch (e) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    },
    async deleteItem() {
      try {
        await this.$API.lead().delete(this.item.id);
        this.closeDialog();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async confirmEmail(id: number) {
      try {
        await this.$API.lead().confirmEmail(id);
        this.closeDialog();
        await this.$store.dispatch(
          "alert/showSuccess",
          "Карточка успешно обновлена!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async closeConnection(user_id: any): Promise<void> {
      try {
        const response = await API.authorize().closeUserConnection(user_id);
        this.cancelSubscription = true;
        if(response.success){
          await this.$store.dispatch(
            "alert/showSuccess",
            response.message
          );
        }else{
          await this.$store.dispatch("alert/showError", response.message);
        }

      } catch (e){

      }
    },
    toggleAside(key: string) {
      for (const item in this.showAside) {
        if (item !== key) {
          this.showAside[item] = false;
        }
      }

      this.showAside[key] = !this.showAside[key];

      if (this.showAside[key]) {
        this.selectedGroup = key;
      } else {
        this.selectedGroup = "";
      }
    },
    showEmails(email: string) {
      const oldEmail = this.selectedEmail;
      this.showAside.emails = true;
      this.showAside.chat = false;
      this.showAside.documents = false;
      this.showAside.subscription = false;
      this.selectedGroup = "email";
      this.selectedEmail = email;

      if (oldEmail && oldEmail === this.selectedEmail) {
        this.$nextTick(() => {
          (this.$refs.mailViewer as Vue).toggleCreateForm();
        });
      }
    },
    closeAside(key: string) {
      this.showAside[key] = false;
      this.selectedGroup = "";
      this.selectedEmail = "";
    },
    openDesktopApp(phone: any) {
      const link = document.createElement("a");

      link.setAttribute("href", `rcapp://r/call?number=${phone.value}`);
      link.click();
    },
    closeEditable() {
      this.editMode = false;
      this.editedItem = {};
    },
    deleteDocument(documentId: number) {
      this.item.documents = this.item.documents.filter(
        (item: any) => item.id !== documentId
      );
    },
    updateSubscription(event: any) {
      this.item.subscription = event;
      this.closeAside("subscription");
    },
    getCurrentUserRole(){
      const { user } = store.getters["authentication/credentials"];
      if (user.role === "admin") {
        this.isAdmin = true;
      }
    }
  }
});
