var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","height":"100%"}},[_c('v-toolbar',{staticStyle:{"border-top-left-radius":"initial"},attrs:{"dense":"","flat":"","height":"72"}},[(!_vm.hideBack)?_c('v-icon',{on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("mdi-arrow-left")]):_vm._e(),_c('v-list-item',{attrs:{"dense":""}},[_c('v-badge',{attrs:{"bordered":"","color":"success","dot":"","offset-x":"19","offset-y":"18"}},[_c('v-avatar',{staticClass:"mr-3 my-2",attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":_vm.room.avatar}})],1)],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"caption font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.room.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" 6 участника ")])],1)],1),_c('v-spacer')],1),_c('v-divider'),_c('div',{staticStyle:{"height":"calc(100% - 74px)"}},[_c('v-card-text',{staticClass:"pa-0 chat-container fill-height"},[_c('div',{ref:"messages",staticClass:"pa-0 overflow-auto overflow-x-hidden position-relative"},[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((_vm.messages),function(message,index){return _c('div',{key:message.id,staticClass:"my-2 mx-1 list-complete-item d-flex align-end",class:{
              'own-message': message.is_owner,
              'foreign-message': !message.is_owner
            }},[_c('v-avatar',{staticClass:"user-avatar",class:{
                'show-user-avatar':
                  !message.is_owner &&
                  (!_vm.messages[index + 1] ||
                    _vm.messages[index + 1].sender_id !== message.sender_id)
              },attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":_vm.room.members[message.sender_id].avatar}})],1),_c('v-card',{attrs:{"flat":"","min-width":"58","tag":"span","width":"fit-content"}},[(!message.is_owner)?_c('div',{staticClass:"caption",staticStyle:{"color":"rgba(255, 255, 255, 0.7)"}},[_vm._v(" "+_vm._s(_vm.room.members[message.sender_id].name)+" ")]):_vm._e(),_c('span',{staticClass:"pa-2 text-left"},[_vm._v(" "+_vm._s(message.text)+" ")]),_c('span',{staticClass:"message-time d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.getMessageTime(message.created_at))+" ")]),(message.is_owner)?[_c('v-icon',{staticClass:"ml-2 check-marker",attrs:{"color":"white","size":"14"}},[_vm._v(" "+_vm._s(message.read_at ? "mdi-check-all" : "mdi-check")+" ")])]:_vm._e()],2),(
                  !_vm.messages[index + 1] ||
                    _vm.messages[index + 1].sender_id !== message.sender_id
                )?_c('div',{staticClass:"tail"}):_vm._e()])],1)}),0)],1),_c('v-card',{staticClass:"pa-2 d-flex input-container align-end grey lighten-4",attrs:{"elevation":"1"}},[_c('v-icon',{staticClass:"mb-2"},[_vm._v(" mdi-paperclip ")]),_c('v-textarea',{ref:"messageInput",staticClass:"message-input mx-2",attrs:{"auto-grow":"","background-color":"","dense":"","hide-details":"","placeholder":"Сообщение","rows":"1","solo":""},on:{"input":function($event){return _vm.scrollToBottom()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendMessage()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-scale-transition',{attrs:{"mode":"out-in","origin":"center"}},[(_vm.message)?_c('v-icon',{key:1,staticClass:"mb-2",on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v(" mdi-send ")]):_c('v-icon',{key:2,staticClass:"mb-2"},[_vm._v(" mdi-microphone ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }